.support {

	.page-header {

		.img {
			width: 42%;
			max-width: 143px;
		}
	}

	&__block {
		margin: 0;
		padding: 35px 15px;

		&__ttl{
			font-size: 23px;
			font-weight: 700;
			text-align: center;
		}

		&__lead{
			margin-top: 30px;
		}


		.inner {
			margin: 0 auto;
			padding: 0;
			width: 100%;
		}
	}

	&__intro {
		padding: 35px 15px 30px;

		.inner {
			max-width: 1000px;
		}
	}

	&__pages {
		padding: 0 15px 55px;

		.inner {
			max-width: 1000px;
		}

		.flexItem {

			&:first-child .support__item {
				margin-top: 30px;
			}

			&:last-child .support__item {
				margin-bottom: 0;
			}
		}
	}

	&__item {
		margin: 53px 0;

		figure {
			img {
				width: 100%;
			}
		}

		.ttl {
			margin-top: 20px;
			font-size: 18px;
			font-weight: 700;
			text-align: center;
		}

		.txt {
			margin-top: 15px;
		}

		.link {
			margin-top: 20px;

			a {
				margin: 0 auto;
				width: 300px;
			}
		}
	}

	//-------------------------------------
	&__note{
		margin: 30px auto 0;

		li{
			padding-left: 28px;
			font-size: 13px;
			line-height: 1.92;
			position: relative;

			span{
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	//------------------------------------------
	&__select {

		// support__select__buttons
		&__buttons {
			margin-top: 56px;

			.flexBox {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: 0 -7px;
			}

			.flexItem {
				margin: 7px 0;
				padding: 0 7px;
				width: 50%;
			}

			.selectBtn {
				padding: 9px 35px 9px 10px;
				width: 100%;
				height: 100%;
				color: #FFF;
				font-size: 13px;
				font-weight: 500;
				text-align: center;
				line-height: 1.54;
				letter-spacing: 0;
				background-color: #0072BC;
				border: 1px solid #0072BC;
				border-radius: 6px;
				cursor: pointer;

				&.on {
					color: #0072BC;
					background-color: #FFF;
					pointer-events: none;
				}

				&:hover {
					color: #0072BC;
					background-color: #F1F6F9;
				}

				@media screen and (max-width: 767px) {
					position: relative;

					&::after {
						content: "";
						display: block;
						width: 18px;
						height: 18px;
						background-image: url(../images/common/icon_arw_btn2.svg);
						background-repeat: no-repeat;
						background-position: 0 0;
						background-size: contain;
						position: absolute;
						right: 5px;
						top: 50%;
						transform: translate(0, -50%) rotate(90deg);
					}

					&.on {
						&::after {
							background-image: url(../images/common/icon_arw_btn3.svg);
						}
					}
				}
			}
		}

		// support__select__contents
		&__contents {

			&__box {
				display: none;

				&.open {
					display: block;
				}

				.box__title {
					margin-bottom: 20px;
					font-size: 16px;
					font-weight: 700;
					text-align: center;
					line-height: 1.875;
					letter-spacing: 0;
				}

				h4 {
					margin: 40px 0 15px;
					font-size: 15px;
					font-weight: 700;
				}

				ol {
					margin: 15px 0;
				}

				p.link_2_1 {
					margin: 25px 0 15px;

					a {
						margin: 0 auto;
						width: 100%;
						height: 79px;
						max-width: 345px;
						text-align: center;
						line-height: 1.4;
					}
				}

				p.link_2_2 {
					margin: 25px 0 15px;

					a {
						margin: 0 auto;
						width: 100%;
						height: 58px;
						max-width: 345px;
						text-align: center;
						line-height: 1.4;
					}
				}

				p.contactBtn {
					margin: 25px 0 15px;
				}

				p.telTxt {

					a {
						margin-right: 5px;
						font-size: 18px;
						font-weight: 700;
					}
				}

				.cont {

					.link{
						text-decoration: none;
					}

					.bottom {
						display: flex;
						justify-content: center;
						margin-top: 32px;
						.icreader {
							width: 560px;
						}
					}

					.tbl_1 {
						tbody {
							th{
								background-color: #F1F6F9;
							}
						}
					}
					.tbl_2 {
						tbody {
							th{
								background-color: #F1F6F9;
							}
						}
					}
				}
			}
		}
	}

}

@media screen and (min-width:768px) {
	.support {

		.page-header {

			.img {
				width: 100%;
				max-width: 290px;
				right: 40px;
			}
		}

		&__block {
			padding: 75px 15px 60px;

			&__ttl{
				font-size: 35px;
			}

			&__lead{
				margin-top: 56px;
				text-align: center;
			}
		}

		&__intro {
			padding: 75px 15px 52px;

			p {
				text-align: center;
			}
		}

		&__pages {
			margin-top: -40px;
			padding: 0 15px 60px;

			.flexBox {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: 56px -40px 0;
			}

			.flexItem {
				padding: 0 40px;
				width: 50%;
			}
		}

		&__item {
			margin: 40px 0 !important;

			.ttl {
				margin-top: 30px;
				font-size: 24px;
			}

			.txt {
				margin-top: 20px;
			}

			.link {
				margin-top: 32px;

				a {
					margin: 0 auto;
					width: 100%;
				}
			}
		}
	}

	@media screen and (min-width: 992px) {
		.support {

			//------------------------------------------
			&__block {
				padding: 80px 15px;

				.inner {
					margin: 0 auto;
					padding: 0;
				}

				ul {
					margin-top: 18px;
				}
			}

			//------------------------------------------
			&__step {

				&__intro {
					padding: 75px 15px 60px;
					text-align: center;
				}

				&__anchor {
					padding: 0 15px 50px;
					max-width: 100%;

					.flexBox {
						display: flex;
						flex-wrap: wrap;
					}

					.flexItem {
						margin: 15px 0 !important;
						width: 33.3333%;

						&:not(:last-child) {
							position: relative;

							&::after {
								content: "";
								display: block;
								width: 30px;
								height: 20px;
								background-image: url(../images/common/icon_arw_down.svg);
								background-repeat: no-repeat;
								background-position: 0 0;
								background-size: contain;
								position: absolute;
								right: 4px;
								top: 50%;
								transform: translate(0, -50%) rotate(-90deg);
							}
						}
					}

					.anchorBtn {
						margin-right: 42px;
						height: 100%;

						&::after {
							width: 24px;
							height: 24px;
						}

						.step {
							width: 74px;
							font-size: 15px;

							.num {
								font-size: 20px;
							}
						}

						.ttl {
							padding: 8px 14px;
							width: calc(100% - 74px);
							font-size: 14px;
						}

						&:hover {
							opacity: 1;
							background-color: #008EEB;
							border: 1px solid #008EEB;

							&::after {
								margin-top: 10px;
							}
						}
					}
				}

				&__block {
					padding: 20px 15px;
					border: 0;

					&>.inner {
						padding: 40px 100px 50px;
						border: 1px solid #C0DBEC;
					}

					&.step01 {
						.support__select__buttons .flexItem {
							width: 25%;
						}
					}

					&.step02,
					&.step03,
					&.step04,
					&.step05,
					&.step06 {

						&::before {
							width: 50px;
							height: 33px;
						}
					}
				}

				&__head {}

				&__body {

					ul {

						&>li {

							&::before {
								transform: translate(-24px, 16px);
							}
						}
					}
				}
			}

			//------------------------------------------
			&__select {

				// support__select__buttons
				&__buttons {
					border-bottom: 1px solid #0072BC;

					.flexBox {
						margin: 0 -15px;
					}

					.flexItem {
						margin: 0 0 -1px;
						padding: 0 15px;
						width: 33.333%;
					}

					.selectBtn {
						margin-bottom: -1px;
						padding: 10px;
						font-weight: 700;
						border-radius: 6px 6px 0 0;

						&.on {
							border-bottom: 1px solid #FFF;
						}
					}
				}

				// support__select__contents
				&__contents {

					&__box {

						p.link_2_1 {

							a {
								width: 100%;
								height: 108px;
								max-width: 625px;
							}

							&+.attent {
								margin: 0 auto;
								max-width: 625px;
							}
						}

						p.link_2_2 {
							margin: 25px 0 15px;

							a {
								width: 100%;
								height: 72px;
								max-width: 625px;
							}
						}
					}
				}
			}

			//------------------------------------------
			&__tbl {
				margin-top: 40px;

				th,
				td {
					padding: 10px;
					font-size: 17px;

					ul {
						margin-top: 0;
						padding-left: 18px;

						&>li {

							&::before {
								width: 8px;
								height: 8px;
								transform: translate(-18px, 16px);
							}
						}
					}
				}

				th {
					padding: 20px 0 20px 30px;
					width: 340px;
					background-color: #F1F6F9;
				}

				td {
					padding: 20px 0 20px 40px;
					width: calc(100% - 340px);
				}
			}

			//------------------------------------------
			&__pageLink {
				padding: 60px 20px;

				a {

					&::after {
						width: 26px;
						height: 18px;
						right: 40px;
					}
				}

				.textarea {
					padding: 40px 67px 40px 60px;

					.t1 {
						width: 45%;
						font-size: 18px;
					}

					.ttl {
						width: 55%;
						font-size: 24px;
						font-weight: 700;
					}

					.t2 {
						margin-top: 5px;
						width: 94%;
						font-size: 16px;
					}
				}
			}

			//------------------------------------------
			.toolBtn {
				margin: 20px 0 15px;

				a {
					width: 100%;
					height: 90px;
					max-width: 408px;
					background-image: url(../images/query/first/tool_btn.png);
					transition: all 0.3s ease;
					position: relative;

					&::after {
						content: "";
						display: block;
						width: 24px;
						height: 24px;
						background-image: url(../images/common/icon_arw_btn2.svg);
						background-repeat: no-repeat;
						background-position: 0 0;
						background-size: contain;
						position: absolute;
						right: 30px;
						top: 50%;
						transition: all 0.3s ease;
						transform: translateY(-50%);
					}

					&:hover {
						background-image: url(../images/query/first/tool_btn_on.png);
						opacity: 1;

						&::after {
							right: 25px;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 1100px) {
		.support {

			//------------------------------------------
			&__step {

				&__anchor {

					.anchorBtn {

						.ttl {
							font-size: 18px;
						}
					}
				}

				&__block {

					&.step06 {
						margin-bottom: 80px;
					}
				}

				&__head {

					.step__title__num {
						padding: 10px 20px;

						.flex-align-center {}
					}

					.step__title__txt {
						padding: 10px 20px;

						.ttl {
							font-size: 24px;
						}
					}

					.step__leadTxt {
						margin-top: 30px;
					}
				}

				&__body {
					margin-top: 40px;

					ul {

						&>li {
							line-height: 2.07;

							&::before {
								transform: translate(-24px, 14px);
							}
						}
					}
				}
			}

			//------------------------------------------
			&__select {

				// support__select__buttons
				&__buttons {

					.selectBtn {
						padding: 20px 5px;
						font-size: 16px;
					}
				}

				// support__select__contents
				&__contents {

					&__box {
						padding: 54px 0;

						.box__title {
							font-size: 21px;
							letter-spacing: 0.05em;
						}

						h4 {
							font-size: 18px;
						}

						p.telTxt {

							a {
								font-size: 20px;
							}
						}

					}
				}

			}

			//------------------------------------------
			&__tbl {
				margin-top: 35px;

				th,
				td {
					padding: 15px 0 15px 30px;
				}

				th {
					padding: 15px 0 15px 30px;
				}

				td {
					padding: 15px 0 15px 40px;
				}
			}

			//------------------------------------------
			&__contents {

				&__box {

					.box__title {
						font-size: 18px;
					}

					figure {
						margin: 40px auto;

						&.step_1_1 {
							max-width: 408px;
						}

						&.step_3_1 {
							max-width: 750px;
						}

						&.step_4_1 {
							max-width: 750px;
						}

						&.step_4_2 {
							max-width: 750px;
						}
					}

					.link_faq {

						a {
							padding-left: 35px;
							font-size: 16px;

							&::before {
								width: 24px;
								height: 24px;
							}
						}
					}
				}
			}

			//------------------------------------------
			.toolBtn {
				margin: 48px 0 15px;
			}

			//------------------------------------------
			&__finish__message {
				margin-top: 60px;

				p {
					padding: 20px 4px;
					font-size: 24px;
				}
			}

		}
	}

}