.support .page-header .img {
  width: 42%;
  max-width: 143px;
}
.support__block {
  margin: 0;
  padding: 35px 15px;
}
.support__block__ttl {
  font-size: 23px;
  font-weight: 700;
  text-align: center;
}
.support__block__lead {
  margin-top: 30px;
}
.support__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.support__intro {
  padding: 35px 15px 30px;
}
.support__intro .inner {
  max-width: 1000px;
}
.support__pages {
  padding: 0 15px 55px;
}
.support__pages .inner {
  max-width: 1000px;
}
.support__pages .flexItem:first-child .support__item {
  margin-top: 30px;
}
.support__pages .flexItem:last-child .support__item {
  margin-bottom: 0;
}
.support__item {
  margin: 53px 0;
}
.support__item figure img {
  width: 100%;
}
.support__item .ttl {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.support__item .txt {
  margin-top: 15px;
}
.support__item .link {
  margin-top: 20px;
}
.support__item .link a {
  margin: 0 auto;
  width: 300px;
}
.support__note {
  margin: 30px auto 0;
}
.support__note li {
  padding-left: 28px;
  font-size: 13px;
  line-height: 1.92;
  position: relative;
}
.support__note li span {
  position: absolute;
  left: 0;
  top: 0;
}
.support__select__buttons {
  margin-top: 56px;
}
.support__select__buttons .flexBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -7px;
}
.support__select__buttons .flexItem {
  margin: 7px 0;
  padding: 0 7px;
  width: 50%;
}
.support__select__buttons .selectBtn {
  padding: 9px 35px 9px 10px;
  width: 100%;
  height: 100%;
  color: #FFF;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  line-height: 1.54;
  letter-spacing: 0;
  background-color: #0072BC;
  border: 1px solid #0072BC;
  border-radius: 6px;
  cursor: pointer;
}
.support__select__buttons .selectBtn.on {
  color: #0072BC;
  background-color: #FFF;
  pointer-events: none;
}
.support__select__buttons .selectBtn:hover {
  color: #0072BC;
  background-color: #F1F6F9;
}
@media screen and (max-width: 767px) {
  .support__select__buttons .selectBtn {
    position: relative;
  }
  .support__select__buttons .selectBtn::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-image: url(../images/common/icon_arw_btn2.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%) rotate(90deg);
  }
  .support__select__buttons .selectBtn.on::after {
    background-image: url(../images/common/icon_arw_btn3.svg);
  }
}
.support__select__contents__box {
  display: none;
}
.support__select__contents__box.open {
  display: block;
}
.support__select__contents__box .box__title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 1.875;
  letter-spacing: 0;
}
.support__select__contents__box h4 {
  margin: 40px 0 15px;
  font-size: 15px;
  font-weight: 700;
}
.support__select__contents__box ol {
  margin: 15px 0;
}
.support__select__contents__box p.link_2_1 {
  margin: 25px 0 15px;
}
.support__select__contents__box p.link_2_1 a {
  margin: 0 auto;
  width: 100%;
  height: 79px;
  max-width: 345px;
  text-align: center;
  line-height: 1.4;
}
.support__select__contents__box p.link_2_2 {
  margin: 25px 0 15px;
}
.support__select__contents__box p.link_2_2 a {
  margin: 0 auto;
  width: 100%;
  height: 58px;
  max-width: 345px;
  text-align: center;
  line-height: 1.4;
}
.support__select__contents__box p.contactBtn {
  margin: 25px 0 15px;
}
.support__select__contents__box p.telTxt a {
  margin-right: 5px;
  font-size: 18px;
  font-weight: 700;
}
.support__select__contents__box .cont .link {
  text-decoration: none;
}
.support__select__contents__box .cont .bottom {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
.support__select__contents__box .cont .bottom .icreader {
  width: 560px;
}
.support__select__contents__box .cont .tbl_1 tbody th {
  background-color: #F1F6F9;
}
.support__select__contents__box .cont .tbl_2 tbody th {
  background-color: #F1F6F9;
}

@media screen and (min-width: 768px) {
  .support .page-header .img {
    width: 100%;
    max-width: 290px;
    right: 40px;
  }
  .support__block {
    padding: 75px 15px 60px;
  }
  .support__block__ttl {
    font-size: 35px;
  }
  .support__block__lead {
    margin-top: 56px;
    text-align: center;
  }
  .support__intro {
    padding: 75px 15px 52px;
  }
  .support__intro p {
    text-align: center;
  }
  .support__pages {
    margin-top: -40px;
    padding: 0 15px 60px;
  }
  .support__pages .flexBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 56px -40px 0;
  }
  .support__pages .flexItem {
    padding: 0 40px;
    width: 50%;
  }
  .support__item {
    margin: 40px 0 !important;
  }
  .support__item .ttl {
    margin-top: 30px;
    font-size: 24px;
  }
  .support__item .txt {
    margin-top: 20px;
  }
  .support__item .link {
    margin-top: 32px;
  }
  .support__item .link a {
    margin: 0 auto;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (min-width: 992px) {
  .support__block {
    padding: 80px 15px;
  }
  .support__block .inner {
    margin: 0 auto;
    padding: 0;
  }
  .support__block ul {
    margin-top: 18px;
  }
  .support__step__intro {
    padding: 75px 15px 60px;
    text-align: center;
  }
  .support__step__anchor {
    padding: 0 15px 50px;
    max-width: 100%;
  }
  .support__step__anchor .flexBox {
    display: flex;
    flex-wrap: wrap;
  }
  .support__step__anchor .flexItem {
    margin: 15px 0 !important;
    width: 33.3333%;
  }
  .support__step__anchor .flexItem:not(:last-child) {
    position: relative;
  }
  .support__step__anchor .flexItem:not(:last-child)::after {
    content: "";
    display: block;
    width: 30px;
    height: 20px;
    background-image: url(../images/common/icon_arw_down.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translate(0, -50%) rotate(-90deg);
  }
  .support__step__anchor .anchorBtn {
    margin-right: 42px;
    height: 100%;
  }
  .support__step__anchor .anchorBtn::after {
    width: 24px;
    height: 24px;
  }
  .support__step__anchor .anchorBtn .step {
    width: 74px;
    font-size: 15px;
  }
  .support__step__anchor .anchorBtn .step .num {
    font-size: 20px;
  }
  .support__step__anchor .anchorBtn .ttl {
    padding: 8px 14px;
    width: calc(100% - 74px);
    font-size: 14px;
  }
  .support__step__anchor .anchorBtn:hover {
    opacity: 1;
    background-color: #008EEB;
    border: 1px solid #008EEB;
  }
  .support__step__anchor .anchorBtn:hover::after {
    margin-top: 10px;
  }
  .support__step__block {
    padding: 20px 15px;
    border: 0;
  }
  .support__step__block > .inner {
    padding: 40px 100px 50px;
    border: 1px solid #C0DBEC;
  }
  .support__step__block.step01 .support__select__buttons .flexItem {
    width: 25%;
  }
  .support__step__block.step02::before, .support__step__block.step03::before, .support__step__block.step04::before, .support__step__block.step05::before, .support__step__block.step06::before {
    width: 50px;
    height: 33px;
  }
  .support__step__body ul > li::before {
    transform: translate(-24px, 16px);
  }
  .support__select__buttons {
    border-bottom: 1px solid #0072BC;
  }
  .support__select__buttons .flexBox {
    margin: 0 -15px;
  }
  .support__select__buttons .flexItem {
    margin: 0 0 -1px;
    padding: 0 15px;
    width: 33.333%;
  }
  .support__select__buttons .selectBtn {
    margin-bottom: -1px;
    padding: 10px;
    font-weight: 700;
    border-radius: 6px 6px 0 0;
  }
  .support__select__buttons .selectBtn.on {
    border-bottom: 1px solid #FFF;
  }
  .support__select__contents__box p.link_2_1 a {
    width: 100%;
    height: 108px;
    max-width: 625px;
  }
  .support__select__contents__box p.link_2_1 + .attent {
    margin: 0 auto;
    max-width: 625px;
  }
  .support__select__contents__box p.link_2_2 {
    margin: 25px 0 15px;
  }
  .support__select__contents__box p.link_2_2 a {
    width: 100%;
    height: 72px;
    max-width: 625px;
  }
  .support__tbl {
    margin-top: 40px;
  }
  .support__tbl th,
  .support__tbl td {
    padding: 10px;
    font-size: 17px;
  }
  .support__tbl th ul,
  .support__tbl td ul {
    margin-top: 0;
    padding-left: 18px;
  }
  .support__tbl th ul > li::before,
  .support__tbl td ul > li::before {
    width: 8px;
    height: 8px;
    transform: translate(-18px, 16px);
  }
  .support__tbl th {
    padding: 20px 0 20px 30px;
    width: 340px;
    background-color: #F1F6F9;
  }
  .support__tbl td {
    padding: 20px 0 20px 40px;
    width: calc(100% - 340px);
  }
  .support__pageLink {
    padding: 60px 20px;
  }
  .support__pageLink a::after {
    width: 26px;
    height: 18px;
    right: 40px;
  }
  .support__pageLink .textarea {
    padding: 40px 67px 40px 60px;
  }
  .support__pageLink .textarea .t1 {
    width: 45%;
    font-size: 18px;
  }
  .support__pageLink .textarea .ttl {
    width: 55%;
    font-size: 24px;
    font-weight: 700;
  }
  .support__pageLink .textarea .t2 {
    margin-top: 5px;
    width: 94%;
    font-size: 16px;
  }
  .support .toolBtn {
    margin: 20px 0 15px;
  }
  .support .toolBtn a {
    width: 100%;
    height: 90px;
    max-width: 408px;
    background-image: url(../images/query/first/tool_btn.png);
    transition: all 0.3s ease;
    position: relative;
  }
  .support .toolBtn a::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../images/common/icon_arw_btn2.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    position: absolute;
    right: 30px;
    top: 50%;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  .support .toolBtn a:hover {
    background-image: url(../images/query/first/tool_btn_on.png);
    opacity: 1;
  }
  .support .toolBtn a:hover::after {
    right: 25px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .support__step__anchor .anchorBtn .ttl {
    font-size: 18px;
  }
  .support__step__block.step06 {
    margin-bottom: 80px;
  }
  .support__step__head .step__title__num {
    padding: 10px 20px;
  }
  .support__step__head .step__title__txt {
    padding: 10px 20px;
  }
  .support__step__head .step__title__txt .ttl {
    font-size: 24px;
  }
  .support__step__head .step__leadTxt {
    margin-top: 30px;
  }
  .support__step__body {
    margin-top: 40px;
  }
  .support__step__body ul > li {
    line-height: 2.07;
  }
  .support__step__body ul > li::before {
    transform: translate(-24px, 14px);
  }
  .support__select__buttons .selectBtn {
    padding: 20px 5px;
    font-size: 16px;
  }
  .support__select__contents__box {
    padding: 54px 0;
  }
  .support__select__contents__box .box__title {
    font-size: 21px;
    letter-spacing: 0.05em;
  }
  .support__select__contents__box h4 {
    font-size: 18px;
  }
  .support__select__contents__box p.telTxt a {
    font-size: 20px;
  }
  .support__tbl {
    margin-top: 35px;
  }
  .support__tbl th,
  .support__tbl td {
    padding: 15px 0 15px 30px;
  }
  .support__tbl th {
    padding: 15px 0 15px 30px;
  }
  .support__tbl td {
    padding: 15px 0 15px 40px;
  }
  .support__contents__box .box__title {
    font-size: 18px;
  }
  .support__contents__box figure {
    margin: 40px auto;
  }
  .support__contents__box figure.step_1_1 {
    max-width: 408px;
  }
  .support__contents__box figure.step_3_1 {
    max-width: 750px;
  }
  .support__contents__box figure.step_4_1 {
    max-width: 750px;
  }
  .support__contents__box figure.step_4_2 {
    max-width: 750px;
  }
  .support__contents__box .link_faq a {
    padding-left: 35px;
    font-size: 16px;
  }
  .support__contents__box .link_faq a::before {
    width: 24px;
    height: 24px;
  }
  .support .toolBtn {
    margin: 48px 0 15px;
  }
  .support__finish__message {
    margin-top: 60px;
  }
  .support__finish__message p {
    padding: 20px 4px;
    font-size: 24px;
  }
}